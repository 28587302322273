<template>
  <div class="">
    <a-input-group compact>
      <a-input style="width: 80%;" v-model="name" disabled placeholder="请查询需求单" allowClear></a-input>
      <a-button style="width: 20%;margin: 0;" @click="toSearch" icon="search"></a-button>
    </a-input-group>

    <a-modal :maskClosable="false" title="选择需求单" style="top: 8px;" width="70%" v-model="visible" :footer="null" @cancel="closeModal">
      <!-- <a-input-search v-model="name2" placeholder="经销商名称" @search="toSearch" style="width: 50%;margin-bottom: 20px;">
        <a-button slot="enterButton">搜索</a-button>
      </a-input-search> -->
      <div class="table">
        <a-table :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}" :customRow="changeTableRow">
          <a-table-column title="需求单号" data-index="demandCode" :width="150" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单状态" data-index="confirmStatus" :width="100" :ellipsis="true" align="center">
            <template slot-scope="text, record">
              <a-tag v-if="text==0" color="#909399">未确认</a-tag>
              <a-tag v-if="text==1" color="#67c23a">已确认</a-tag>
            </template>
          </a-table-column>
          <a-table-column title="收货人" data-index="customerName" :width="200" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="总数/已发" data-index="sendGoodsCount" :width="80" :ellipsis="true" align="center">
            <template slot-scope="text, record">{{record.goodsCount}}/{{record.sendGoodsCount}}</template>
          </a-table-column>
          <a-table-column title="地址" data-index="address" :width="400" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单生成方式" data-index="sourceType" :width="100" :ellipsis="true" align="left">
            <template slot-scope="text, record">{{text|sourceType}}</template>
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        visible: false,
        name: '',
        name2: '',
        tableData: [],
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          size: 'small'
        },

      }
    },
    props: ['value','customerid'],
    watch: {
      value(newVal) {
        this.name = newVal
      }
    },
    methods: {
      closeModal() {
        this.name2 = ''
      },
      toSearch(type) {
        if(!this.customerid){
          return this.$message.warning("请先选择经销商")
        }
        this.visible=true
        const postData = Object.assign({}, {
          customerId:this.customerid,
          pageNumber: type == 2 ? this.page.current : this.page.current = 1,
          pageSize: this.page.pageSize,
          confirmStatus: 1
        })
        this.axios.get(`/api/order/system/orderDemand/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      changeTableRow(record) {
        return {
          on: {
            dblclick: e => {
              this.visible = false
              this.$emit('update:value', record.demandCode)
              this.$emit('select', record)
            }
          }
        }
      },
    },
    created() {
      if (this.value) {
        this.name = this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
